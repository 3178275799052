import React, { useState } from "react"
import styled from "styled-components"
import Modal from "react-bootstrap/Modal"
import Table from "react-bootstrap/Table"

const PointInfo = styled.span`
  font-family: CeraGR-Regular;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`
const PointListItemWrapper = styled.div`
  padding: 24px;
  margin: 0 4px;

  &:first-child {
    padding-top: ${props => (props.filtered ? "24px" : 0)};

    @media screen and (max-width: 991px) {
      padding-top: 1.5rem;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }

  background-color: ${props =>
    props.filtered ? props.theme.colors.blue : "unset"};
  h5,
  span {
    color: ${props => (props.filtered ? "white" : "#002F6C")};
  }
`
const PointListItem = props => {
  const { member, ...rest } = props
  const [show, setShow] = useState(false)

  return (
    <PointListItemWrapper {...rest}>
      <h5
        css={`
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        `}
        className="mb-1"
        role="button"
        onClick={() => setShow(!show)}
      >
        {member.data.name.text}
      </h5>
      <PointInfo>
        {member.data.address.text} <br />
        {member.data.telephone && (
          <>
            Τηλ.: {member.data.telephone} <br />
          </>
        )}
        {member.data.fax && (
          <>
            Fax: {member.data.fax} <br />
          </>
        )}
        {member.data.email && <>Email: {member.data.email}</>}
      </PointInfo>
      <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
        <Modal.Header
          closeButton
          css={`
            &&& {
              border-bottom: none;
              padding: 16px 24px;
            }
          `}
        >
          <Modal.Title>{member.data.name.text}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          css={`
            &&& {
              padding: 0 16px;
            }
          `}
        >
          <Table
            css={`
              border-collapse: collapse;
              border-radius: 4px;
              border-style: hidden;
              box-shadow: 0 0 0 1px rgba(0, 47, 108, 0.2);

              &&& {
                th {
                  background: rgba(21, 123, 255, 0.1);
                }

                tbody {
                  border-top: none;
                }
              }
            `}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Θέση</th>
                <th>Όνομα</th>
              </tr>
            </thead>
            <tbody>
              {member.data.members.map(x => (
                <tr key={x.full_name}>
                  <td>{x.position}</td>
                  <td>{x.full_name}</td>
                </tr>
              ))}
              {!Boolean(member.data.members.length) && (
                <tr>
                  <td
                    css={`
                      text-align: center;
                    `}
                    colSpan={2}
                  >
                    -
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </PointListItemWrapper>
  )
}

export default PointListItem
